/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../css/app.scss';

$( "#addUrl").click(function() {
    $('.urlerror').remove();
    var urltext = $('#urltext').val();
    if(!$('#urltext').val()){
        $('#urldiv').append('<p class="text-start m-0 mt-2 ps-5 urlerror">Please Enter Valid URL</p>');
        return false;
    }
    if (urltext.indexOf("http://") === 0 || urltext.indexOf("https://") === 0) {
        var url_validate = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
        if(urltext && !url_validate.test(urltext)){
            $('#urldiv').append('<p class="text-start m-0 mt-2 ps-5 urlerror">Please Enter Valid URL</p>');
            return false;
        }
        $.post("/insert_url?url=" + urltext, function( data ) {
            if(!data.success){
                $('#title_error').remove();
                $('#urldiv').append($('<p class="form-text text-danger m-2 urlerror">'+data.message+'</p>'));
            }
            else {
                location.reload();
            }
        });
    } else {
        $('#urldiv').append('<p class="text-start m-0 mt-2 ps-5 urlerror">Please Enter Valid URL with https</p>');
        return false;
    }
   
});

$('.editlink').click(function() {
    var domainId = $(this).data('id');
        var domainURL = $(this).closest('tr').find('td:nth-child(1)').text();
        var domainStatus = $(this).closest('tr').find('td:nth-child(2)').text() === 'Enabled';

        // Populate fields in the popup dialog
        $('#newurl').val(domainURL);
        $('#statusCheckbox').prop('checked', domainStatus);
        $('#dataid').val(domainId);
});

$( "#edit_urlinfo").click(function() {
    var dataId = $('#dataid').val();
    var url = '/update_url/'+dataId;
    $('.urlerror').remove();
    var urltext = $('#newurl').val();
    var domainStatus = $('#statusCheckbox').prop('checked') ? 1 : 0;
    if(!$('#newurl').val()){
        $('.modal-body').append('<p class="text-start m-0 mt-2 ps-5 urlerror">Please Enter Valid URL</p>');
        return false;
    }
    if (urltext.indexOf("http://") === 0 || urltext.indexOf("https://") === 0) {
        var url_validate = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/\S*)?$/;
        if(urltext && !url_validate.test(urltext)){
            $('.modal-body').append('<p class="text-start m-0 mt-2 ps-5 urlerror">Please Enter Valid URL</p>');
            return false;
        }
        $.post(url, {newurl: urltext,domainStatus:domainStatus}, function(data){
            if(data.success){
                location.reload();
            }
        }); 
    } else {
        $('.modal-body').append('<p class="text-start m-0 mt-2 ps-5 urlerror">Please Enter Valid URL with https</p>');
        return false;
    }          
});

$( ".deletelink").click(function() {
    var domainId = $(this).data('urlid');
    var url = '/delete_url/'+domainId;
    $.post(url, function(data){
        if(data.success){
            location.reload();
        }
    });      
});